<template>
  <el-dialog
    :visible.sync="ssoVisible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="300px"
    top="300px"
  >
    <div class="login-tip">
      <i
        class='iconfont-google icon-Warning'
        style='font-size: 32px; color: red'></i>
      <p class="title">{{$t('ssoLoginTitle')}}</p>
      <p class="des">{{$t('ssoLogin')}}</p>
      <el-button
        class="confirmBtn"
        @click="ssoVisible = false">{{$t('ok')}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Bus from '@/assets/js/vueBus'
export default {
  name: 'LoginTipSSO',
  data () {
    return {
      ssoVisible: false
    }
  },
  created () {
    Bus.$on('ssoChange', (val) => {
      this.ssoVisible = val
    })
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog__header {
  padding: 0;
}
.login-tip {
  text-align: center;

  .title {
    font-weight: 700;
    font-size: 20px;
    margin: 10px 0;
    color: #20130B;
  }

  .des {
    color: #666666;
    margin-bottom: 20px;
  }

  .confirmBtn {
    width: 96px;
    height: 40px;
    font-size: 14px;
    background: #33AB4F;
    color: #fff;
    border: none;
  }
}
</style>
