<template>
  <div class="sign-in">
    <form
      id="loginForm"
      method="post">
      <input
        type="text"
        id="SessionID"
        name="SessionID"
        required>
      <button type="submit">Submit</button>
    </form>
    <div class="flex-start-between marginB35">
      <div>
        <h2 class="fs-24 marginB26">{{$t('signIn')}}</h2>
        <p class="gray">{{$t('welcomeBack')}}</p>
        <p class="gray">{{$t('continue')}}</p>
      </div>
      <!--  隐藏gridLink app端注册按钮  -->
      <div
        v-if="isShowSingUp"
        class="sign-btn"
        @click="routerPush('/signUp')">{{$t('signUp')}}</div>
    </div>
    <other-login></other-login>
    <!--    <div class="fs-13 gray marginB26" v-if="isPc">{{msgType == 1 ? $t('orEmail') : $t('orTel')}}</div>-->
    <div
      class="fs-13 gray marginB26"
      v-if="isPc">{{$t('orEmail')}}</div>
    <el-form
      :model="signForm"
      :rules="rules"
      ref="signForm">
      <el-form-item
        prop="account"
        class="account-form">
        <!--        <div class="account-icon cursor">-->
        <!--          <i-->
        <!--            class="iconfont icon-phone fs-20 marginR15"-->
        <!--            :class="msgType == 2 ? 'primary' : '' "-->
        <!--            @click="changeAccountType(2)"></i>-->
        <!--          <i-->
        <!--            class="iconfont icon-Mail fs-20"-->
        <!--            :class="msgType == 1 ? 'primary' : '' "-->
        <!--            @click="changeAccountType(1)"></i>-->
        <!--        </div>-->
        <div class="account-icon cursor">
          <i class="iconfont icon-phone fs-20 marginR10"></i>
          <span class="line-icon"></span>
          <i class="iconfont icon-Mail fs-20"></i>
        </div>
        <el-input
          class="account-input"
          tabindex='1'
          autocomplete
          v-model="signForm.account"
          @keyup.enter.native="signInChanges"
          @change="changesInput"
          id="account"
          :placeholder="$t('emailPlaceholder')"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          prefix-icon="iconfont icon-Lock fs-22"
          tabindex='2'
          autocomplete
          type="password"
          v-model="signForm.password"
          show-password
          @keyup.enter.native="signInChanges"
          @change="changesInput"
          id="password"
          ref="password"
          :placeholder="$t('password')"></el-input>
      </el-form-item>
    </el-form>
    <div class="fs-12 gray marginB5 cursor">
      <span
        class="border"
        @click="routerPush('/forget')">{{$t('forgotPsd')}}</span>
    </div>
    <div class="fs-12">
      <span class="gray">{{$t('needHelp')}}</span>
      <el-popover
        placement="bottom-start"
        :title="$t('contactUs')"
        width="240"
        trigger="click">
        <p class="marginB10">{{$t('helpWay')}}</p>
        <p class="primary">
          <a
            v-if="isPc"
            href="mailto:support@tvunetworks.com">support@tvunetworks.com.</a>
          <span
            v-if="!isPc"
            @click="sendEmail">support@tvunetworks.com.</span>
        </p>
        <span
          slot="reference"
          class="primary border cursor">{{$t('contact')}}</span>
      </el-popover>
    </div>
    <div class="btn">
      <!-- :disabled='isDisabled' -->
      <el-button
        v-repeat-click="{event:signInChanges}"
        class="sign-submit-btn"
        :loading="isLoading">{{$t('signIn')}}</el-button>
    </div>
    <div
      class="gray ac fs-13"
      v-if="!isCn">
      {{$t('orEnterprise')}}
      <span
        class="primary cursor"
        @click="routerPush('/singleSign')">{{$t('enterpriseLogin')}}</span>
    </div>
    <login-tip-sso></login-tip-sso>
    <div
      v-if="isShowPrivacyTypes"
      class="fs-13 agree-info"
      style="margin-top: 35px;">
      {{$t('signInType')}}{{$t('agreeInfo')}}
      <span
        style="white-space: nowrap;"
        class="border cursor agree-item"
        @click="jumpToPolicy(2)">{{$t('termsInfo')}}</span>
      {{$t('and')}}
      <span
        class="border cursor agree-item"
        @click="jumpToPolicy(1)">{{$t('policyInfo')}}</span>
    </div>
  </div>
</template>

<script>
import OtherLogin from '../components/OtherLogin'
import LoginTipSso from '../components/LoginTipSSO'
import { mapState } from 'vuex'
import Bus from '@/assets/js/vueBus'
import { getUrlParam } from '@/assets/js/utils'
const sha512 = require('js-sha512')

export default {
  name: 'SignInPage',
  data () {
    return {
      signForm: {
        account: '',
        password: ''
      },
      isDisabled: true,
      isLoading: false,
      isShowSingUp: true,
      isShowPrivacyTypes: true,
      domainName: '',
      isCn: false
      // msgType: 1
    }
  },
  components: {
    OtherLogin,
    LoginTipSso
  },
  watch: {
    signForm: {
      handler (val) {
        if (val.account && val.password) {
          this.isDisabled = false
        } else {
          this.isDisabled = true
        }
      },
      deep: true
    }
  },
  computed: {
    rules () {
      return {
        account: [
          // { required: true, message: this.msgType == 1 ? this.$t('emailSignTip') : this.$t('telSignTip'), trigger: 'blur' }
          { required: true, message: this.$t('emailOrTelSignTip'), trigger: 'blur' }
        ],
        password: [{ required: true, message: this.$t('passwordTip'), trigger: 'blur' }]
      }
    },
    ...mapState({
      httpsFlag: 'httpsFlag',
      tagKeys: 'tagKeyList',
      publicServers: 'publicServers',
      hideRegisterServices: 'hideRegisterServices',
      hidePrivacyTypes: 'hidePrivacyTypes',
      isPc: 'isPc',
      showPopup: 'showPopup',
      clearCookiePathList: 'clearCookiePathList'
      // isShowMyID: 'isShowMyID',
      // key: 'key',
      // searchRegisterUrl: 'searchRegisterUrl',
    })
  },
  mounted () {
    if (window.location.host.includes('cn')) {
      this.isCn = true
    }
    this.$nextTick(() => {
      this.changesInput()
      if (!this.signForm.account && !this.signForm.password) {
        this.$refs.signForm.resetFields()
      }
      // else {
      //   // 如果账号类型是电话，类型切换为电话
      //   if ((/^1[0-9]{10}$/.test(this.signForm.account))) {
      //     this.msgType = 2
      //   }
      // }
    })
    // 获取记住密码相关信息
    this.getCookie()
    const href = window.location.href
    this.$store.state.href = href

    const localUrl = window.location.hostname
    const str1 = localUrl.split('.')[1]
    const str2 = localUrl.split('.')[2]
    this.domainName = `.${str1}.${str2}`

    Bus.$off('canClearSid')
    Bus.$on('canClearSid', () => {
      this.getDomainName()
    })
    // 非公共服务不展示注册入口,app展示注册入口
    // const appFlag = href.includes('type=')
    // this.isShowSingUp = this.publicServers.includes(getUrlParam('serviceName')) || appFlag
    if (this.hideRegisterServices && this.hideRegisterServices.length < 1) {
      this.hideRegisterServices.push('TVU GridLink')
    }
    if (this.hidePrivacyTypes && this.hidePrivacyTypes.length < 1) {
      this.hidePrivacyTypes.push('tvuanywhere')
    }
    // 目前只隐藏gridLink app端注册按钮
    this.isShowSingUp = !(this.hideRegisterServices.includes(getUrlParam('serviceName')) && !this.isPc)
    // 隐藏隐私政策 隐藏在hidePrivacyTypes中的type
    this.isShowPrivacyTypes = !this.hidePrivacyTypes.includes(getUrlParam('type'))
    Bus.$emit('showGoogleTipEvent')
  },
  methods: {
    jumpToPolicy (type) {
      const network = localStorage.getItem('lang') === 'zh' ? 'cn' : 'com'
      ga('send', 'event', 'newUserService-createAccount-goToPolicy', 'click')
      if (type == 1) {
        window.open(`https://www.tvunetworks.${network}/company-privacy-notice/`, '_blank')
      } else {
        window.open(`https://www.tvunetworks.${network}/company-terms-and-conditions/`, '_blank')
      }
    },
    sendEmail () {
      // app端唤起邮箱
      const email = 'support@tvunetworks.com'
      const u = navigator.userAgent
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端

      if (isAndroid) { // 安卓机型
        Android.SendMail(JSON.stringify({
          email: email
        }))
      } else { // ios机型
        window.webkit.messageHandlers.SendMail.postMessage(JSON.stringify({
          email: email
        }))
      }
    },
    // changeAccountType (val) {
    //   this.msgType = val
    //   this.signForm.account = ''
    //   this.$nextTick(() => {
    //     this.$refs.signForm.clearValidate('account')
    //   })
    // },
    changesInput () {
      const passwordValue = $('#password').val()
      const accountValue = $('#account').val()
      if (passwordValue != '' && this.signForm.password != passwordValue) {
        this.signForm.password = passwordValue
      }
      if (accountValue != '' && this.signForm.password != accountValue) {
        this.signForm.account = accountValue
      }
      if (this.signForm.account && this.signForm.password) {
        this.isDisabled = false
      } else {
        this.isDisabled = true
      }
    },
    getCookie: function () {
      if (document.cookie.length > 0) {
        const arr = document.cookie.split('; ') // 这里显示的格式需要切割一下自己可输出看下

        for (let i = 0; i < arr.length; i++) {
          const arr2 = arr[i].split('=') // 再次切割
          // 判断查找相对应的值
          if (arr2[0] == 'userLoginName') {
            this.signForm.account = decodeURIComponent(escape(window.atob(arr2[1]))) // 保存到保存数据的地方
          } else if (arr2[0] == 'userPwd') {
            this.signForm.password = decodeURIComponent(escape(window.atob(arr2[1])))
          }
        }
      }
    },
    getDomainName () {
      const exp = new Date()
      exp.setTime(exp.getTime() - 1)
      // window.document.cookie = `SID=;path=/;domain=${this.domainName};expires=${exp.toGMTString()};${this.httpsFlag ? 'Secure=true;' : ''}`
      // window.document.cookie = `SID=;path=/;domain=${this.domainName};expires=${exp.toGMTString()};`
      this.tagKeys.forEach((item) => {
        window.document.cookie = `${item}=;path=/;domain=${this.domainName};expires=${exp.toGMTString()}`
      })
    },
    // 需要在登录或者注册之前清除cookie里的SID(不同path下的SID)
    clearPopupCookie () {
      const exp = new Date()
      exp.setTime(exp.getTime() - 1)
      this.clearCookiePathList.forEach((item) => {
        window.document.cookie = `SID=;path=${item};domain=${this.domainName};expires=${exp.toGMTString()};${this.httpsFlag ? 'Secure=true;' : ''}`
        window.document.cookie = `SID=;path=${item};domain=${this.domainName};expires=${exp.toGMTString()}`
      })
    },
    routerPush (path) {
      ga('send', 'event', 'newUserService-singIn-leave', 'click')
      // this.clearPopupCookie()
      const href = window.location.href
      const flag = href.includes('type=')
      if (flag) {
        // 移动端需要携带本来url上的参数
        const url = path + '?' + href.split('?')[1]
        this.$router.push({ path: url })
      } else {
        this.$router.push(path)
      }
    },
    // 解析地址参数并返回obj参数
    getParams (paramStr) {
      const paramsObj = {}
      if (paramStr.includes('&')) {
        paramStr.split('&').forEach(item => {
          const str = item.split('=')
          paramsObj[str[0]] = str[1]
        })
      } else {
        const str = paramStr.split('=')
        paramsObj[str[0]] = str[1]
      }
      return paramsObj
    },
    // 清除tagkey
    clearTagKey () {
      const exp = new Date()
      exp.setTime(exp.getTime() - 1)
      this.tagKeys.forEach((item) => {
        window.document.cookie = `${item}=;path=/;domain=${this.domainName};expires=${exp.toGMTString()}`
      })
    },
    signInChanges (tag) {
      // ga('send', 'event', 'newUserService-singIn-login', 'click')
      const that = this
      if (this.isLoading) {
        return
      }
      // this.clearPopupCookie()
      this.$refs.signForm.validate((valid) => {
        if (!valid) {
          return
        }
        // 移动端url
        const href = window.location.href
        // let href = 'http://211.160.178.10:20088/newuserservice/#/signIn?type=tvuhitme&peerId=1234&deviceName=aaa&vertion=111&deviceType=r';
        const condition = {
          account: that.signForm.account.replace(/\s*/g, ''),
          password: sha512(that.signForm.password).toUpperCase()
        }

        let flag = false// false web;true anywhere

        if (href.includes('type=')) {
          // 移动端传参
          flag = true
          let val = []

          val = decodeURIComponent(href).split('?')
          const paramsObj = this.getParams(val[1])

          Object.assign(condition, paramsObj)
        } else {
          let val

          flag = false
          // web 传参https://newuserservice.tvunetworks.com/newuserservice/?url=https://cc.tvunetworks.com/tvucc&serviceName=Command%20Center#/signIn
          let url

          let envFlag = true

          if (href.indexOf('?') > -1) {
            if (process.env.NODE_ENV === 'production' && !href.includes('newloginservice')) {
              envFlag = false
            }
            if (envFlag) {
              const encodeHref = decodeURIComponent(href)

              val = encodeHref.substring(encodeHref.indexOf('?') + 1)
              if (val.includes('serviceName')) {
                val = val.split('url=')[1].split('#')[0].split('&serviceName=')
                url = val[0]
                condition.serverName = getUrlParam('serviceName')
                condition.url = url
              }
              // webR传peerId
              if (getUrlParam('peerId')) condition.peerId = getUrlParam('peerId')
            }
            that.$store.state.location = url// decodeURIComponent(href).split('?')[1].split('#')[0].split('url=')[1]
          } else {
            condition.loginType = 1
          }
        }
        this.isLoading = true
        // 请求并跳转
        if (tag && tag == 'loginFlag') {
          condition.loginFlag = 1
        }
        // tvu home邀请加入自己的组，用户注册时，需要携带inviteGroupId参数
        const keys = ['awsCustomerId', 'awsProductCode', 'awsAccountId', 'inviteGroupId']
        keys.forEach(key => {
          let value = getUrlParam(key)
          if (value !== null) {
            condition[key] = value
          }
        })
        this.$axios.post(
          '/login-service/login/signIn',
          condition
        ).then((res) => {
          this.isLoading = false
          // res.data.errorCode = '80943006';
          if (res.data.errorCode == '0x0') {
            // let session = res.data.result;
            const data = res.data.result

            // 登录成功存储sid
            // this.remenberPsw()
            localStorage.setItem('email', that.signForm.account)
            localStorage.setItem('firstName', data.firstName)
            localStorage.setItem('userName', data.userName)
            localStorage.setItem('session', data.session)
            localStorage.setItem('userservice', data.userservice)
            window.userservice = data.userservice
            // media hub弹窗内登录成功之后，先将tagkey清除，再存储
            // if (this.showPopup) {
            //   this.clearTagKey()
            // }
            // 登录成功之后，先将tagkey清除，再存储
            this.clearTagKey()
            if (this.domainName && getUrlParam('type') != 'tvuanywhere') {
              // window.document.cookie = `SID=${data.session};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
              if (data.tagList && data.tagList.length > 0 && this.$store.state.saveTagCookie) {
                data.tagList.forEach(itm => {
                  window.document.cookie = `${itm.tagKey}=${itm.tagValue};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
                })
              }
            }
            // 此时设置sid有效
            this.$store.commit('changesValidSid', true)
            if (flag) {
              // 移动调用方法
              if (getUrlParam('type') != 'tvuanywhere') {
                // window.document.cookie = `SID=${data.session};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
                if (data.tagList && data.tagList.length > 0 && this.$store.state.saveTagCookie) {
                  data.tagList.forEach(itm => {
                    window.document.cookie = `${itm.tagKey}=${itm.tagValue};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
                  })
                }
              }
              // data.tvutag?window.document.cookie = "tvutag=" +data.tvutag+ ";path=/;domain=" + this.domainName + ";":"";
              const u = navigator.userAgent
              const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端

              if (isAndroid) { // 安卓机型
                // getUserInfo(data.userId, data.session);
                Android.getUserInfo(JSON.stringify({
                  userId: data.userId,
                  session: data.session,
                  firstName: data.firstName,
                  email: data.email
                }))
              } else { // ios机型
                window.webkit.messageHandlers.getUserInfo.postMessage(JSON.stringify({
                  userId: data.userId,
                  session: data.session,
                  firstName: data.firstName,
                  email: data.email
                }))
                // 通知gridlink登录成功
                window.webkit.messageHandlers.hideNavIfLogin.postMessage(JSON.stringify({}))
              }
            } else {
              // web跳转
              if (data.loginWelcome == '1') {
                this.$router.push('/welcome')
              } else {
                if (getUrlParam('peerId')) {
                  this.loginWebR(data.session)
                } else {
                  window.top.location.href = data.redirectUrl
                }
              }
            }
          } else if (res.data.errorCode == '80943001') {
            this.$message({
              message: this.$t('error80943001'),
              type: 'error',
              duration: 5000
            })
          } else if (res.data.errorCode == '80943003') {
            this.$message({
              message: this.$t('error80943003'),
              type: 'error',
              duration: 3000
            })
          } else if (res.data.errorCode == '80943004') {
            this.$message({
              message: this.$t('error80943004'),
              type: 'error',
              duration: 5000,
              offset: 80
            })
          } else if (res.data.errorCode == '80943009') {
            this.$message({
              message: this.$t('error80943009'),
              type: 'error',
              duration: 3000
            })
          } else if (res.data.errorCode == '80943010') {
            let info = this.$i18n.locale == 'zh' ? '，还有' : '. '
            this.$message({
              message: this.$t('error80943001') + info + res.data.errorInfo + this.$t('error80943010'),
              type: 'error',
              duration: 3000
            })
          } else if (res.data.errorCode == '80943007') {
            Bus.$emit('googleChange', true)
          } else if (res.data.errorCode == '80943011') {
            Bus.$emit('ssoChange', true)
          } else {
            this.$message({
              message: res.data.errorInfo,
              type: 'error',
              duration: 3000
            })
          }
        }).catch(() => {
          this.isDisabled = false
        })
      })
    },
    loginWebR (session) {
      const form = document.getElementById('loginForm')
      const sessionIDInput = document.getElementById('SessionID')
      // 设置动态生成的值
      form.action = getUrlParam('url')
      sessionIDInput.value = session
      // 自动提交表单
      form.submit()
    }
  }
}
</script>

<style lang="less" scoped>
.otherLogin {
  margin: 36px 48px 0;
}

.otherLogin img {
  width: 86px;
}

.btn {
  margin: 48px 0 37px;
}

.account-form {
  position: relative;
  .account-icon {
    position: absolute;
    z-index: 9;
    color: #C0C4CC;
    left: 12px;
    top: 4px;
  }
}

.account-input .el-input__inner {
  padding-left: 86px;
}

.line-icon {
  position: absolute;
  height: 21px;
  border-right: solid .5px #979797;
  display: inline-block;
  top: 9px;
  left: 25px;
  transform: rotate(25deg);
}
#loginForm {
  display: none;
}
</style>
